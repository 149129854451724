var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"performance"},[_c('div',{staticClass:"performanceChart-box"},[_c('div',{ref:"performanceChart",staticClass:"performanceChart"})]),_c('div',{staticClass:"table-box"},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"green-td",attrs:{"rowspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Question Type"))+" ")]),_c('th',{staticClass:"green-td",attrs:{"rowspan":"2"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.This Test"))+" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('pageTitle.Your performance on this test'),"placement":"top"}},[_c('span',[_c('i',{staticClass:"fa fa-question-circle question-icon"})])])],1)]),_c('th',{staticClass:"green-td",attrs:{"rowspan":"2"}},[_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('pageTitle.Your cumulative average'),"placement":"top"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.My Average"))+" "),_c('i',{staticClass:"fa fa-question-circle question-icon"})])])],1)]),_c('th',{staticClass:"green-td",attrs:{"rowspan":"2"}},[_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('pageTitle.Everyone’s average for this question type'),"placement":"top"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.Average"))+" "),_c('i',{staticClass:"fa fa-question-circle question-icon"})])])],1)]),_c('th',{staticClass:"green-td",staticStyle:{"width":"20rem"},attrs:{"rowspan":"2"}},[_vm._v(" Questions ")])]),_c('tr')]),_c('tbody',_vm._l((_vm.tags),function(tag,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left",staticStyle:{"width":"150px"}},[_c('el-tag',{staticClass:"ivy-tag",attrs:{"type":"success","size":"small"}},[_c('b',[_vm._v(" "+_vm._s(tag.name)+" ")])])],1),_c('td',{staticStyle:{"width":"150px"},domProps:{"innerHTML":_vm._s(
              _vm.getAccuracy(
                tag.user_exam_correct_count,
                tag.user_exam_question_count
              )
            )}}),_c('td',{staticStyle:{"width":"100px"},domProps:{"innerHTML":_vm._s(_vm.getAvg(tag.user_correct_count, tag.user_answered_count))}}),_c('td',{staticStyle:{"width":"100px"},domProps:{"innerHTML":_vm._s(_vm.getAvg(tag.correct_count, tag.answered_count))}}),_c('td',{staticClass:"text-left",staticStyle:{"padding":"5px"}},_vm._l((tag.question_orders),function(question,key){return _c('router-link',{key:key,attrs:{"to":{
                name: 'ACTExplanations',
                params: {
                  examId: _vm.examId,
                  userExamId: _vm.userExamId
                },
                query: {
                  sectionOrder: question.split('-')[0],
                  questionOrder: question.split('-')[1]
                }
              }}},[_c('span',{class:question.split('-')[2] == 1
                    ? 'view-question correct'
                    : 'view-question error'},[_vm._v(" "+_vm._s(question.split("-")[1])+" ")])])}),1)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }