<template>
  <div>
    <div v-for="(passage, pIndex) in list" :key="pIndex" class="mb-4">
      <h4>
        {{ passage.name }}:
        <span>
          <span style="color: #ff8920;">{{ passage.correctCount }}</span
          >/{{ passage.exam_questions.length }}</span
        >
        <!-- <span class="ml-2 text-info">[{{ sectionTotalTime }}]</span> -->
      </h4>
      <div
        class="answer-table"
        v-for="i in Math.ceil(passage.exam_questions.length / lineCount)"
        :key="i"
      >
        <table class="table table-bordered">
          <thead>
            <tr class="rnum_1">
              <th class="green-td">#</th>
              <!-- <th class="green-td">Your Answer</th> -->
              <th class="green-td">Your Answer</th>
              <!-- <th class="green-td">Correct Answer</th> -->
              <th class="green-td">Answer Time</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(question, index) in passage.exam_questions"
              :key="question.order"
              v-show="(i - 1) * lineCount <= index && index < i * lineCount"
            >
              <td class="green-td">{{ question.order }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'ACTExplanations',
                    params: {
                      examId: examId,
                      userExamId: userExamId
                    },
                    query: {
                      sectionOrder: pIndex + 1,
                      questionOrder: index + 1
                    }
                  }"
                >
                  <div>
                    <template v-if="question.userAnswer">
                      <template v-if="question.userAnswer.answer[0]">
                        <b
                          v-show="showAnswer"
                          :class="
                            question.userAnswer.is_correct === 1
                              ? 'text-success'
                              : 'text-danger'
                          "
                        >
                          {{
                            question.userAnswer.answer[0]
                              ? getLetter(
                                  question.order,
                                  question.userAnswer.answer[0]
                                )
                              : "-"
                          }}
                        </b>
                        <div v-show="!showAnswer">
                          <div
                            class="text-success"
                            v-if="question.userAnswer.is_correct === 1"
                          >
                            <i class="fa fa-check"></i>
                          </div>
                          <div v-else class="text-danger">
                            <i class="fa fa-times"></i>
                          </div>
                        </div>
                      </template>
                      <b style="color: #ccc;" v-else>
                        -
                      </b>
                    </template>
                    <b style="color: #ccc;" v-else>
                      -
                    </b>
                  </div>
                </router-link>
              </td>
              <!-- <td>
                <router-link
                  :to="{
                    name: 'ACTExplanations',
                    params: {
                      examId: examId,
                      userExamId: userExamId
                    },
                    query: {
                      sectionOrder: pIndex + 1,
                      questionOrder: index + 1
                    }
                  }"
                >
                  <div class="text-success" v-show="showAnswer">
                    <b
                      v-for="answer in question.question.answers"
                      :key="answer.id"
                    >
                      {{
                        answer && answer.answer && answer.answer[0]
                          ? getLetter(question.order, answer.answer[0])
                          : "?"
                      }}
                    </b>
                  </div>
                  <b v-show="!showAnswer">
                    *
                  </b>
                </router-link>
              </td> -->
              <td>
                <router-link
                  :to="{
                    name: 'ACTExplanations',
                    params: {
                      examId: examId,
                      userExamId: userExamId
                    },
                    query: {
                      sectionOrder: pIndex + 1,
                      questionOrder: index + 1
                    }
                  }"
                >
                  <span class="text-info" v-if="question.userAnswer">
                    {{
                      question.userAnswer.metadata
                        ? showTotalTime(question.userAnswer.metadata.activities)
                        : ""
                    }}
                  </span>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4 class="mt-4">Questions Timeline for {{ passage.name }}:</h4>
      <TimeTable
        class="mt-4"
        :sectionTime="getSectionTime(passage.name)"
        :questions="passage.exam_questions"
      ></TimeTable>
      <hr />
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Common from "@/mixins/common.js";
import { instant } from "@ivy-way/material";
import TimeTable from "@/views/act/actResult/components/TimeTable";

export default {
  components: { TimeTable },

  mixins: [Common],

  props: [
    "showAnswer",
    "examId",
    "list",
    "score",
    "raw",
    "count",
    "userExamId",
    "practiceType",
    "passageIndex"
  ],
  data() {
    return {
      lineCount: 10
    };
  },
  computed: {
    instant() {
      return instant;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    sectionTotalTime() {
      let time = 0;
      let passage = this.list[0];
      let questions = passage.exam.exam_questions;
      questions.forEach(question => {
        if (question.userAnswer) {
          let spend = question.userAnswer.other_status
            ? this.totalTime(question.userAnswer.other_status.activities)
            : 0;
          time += spend;
        }
      });
      return this.instant.formatSecondToMMSS(time);
    }
  },
  watch: {},

  mounted() {},

  methods: {
    getSectionTime(sectionName) {
      let testTime = 60 * 60;
      if (sectionName == "English") {
        testTime = 45 * 60;
      } else if (sectionName == "Math") {
        testTime = 60 * 60;
      } else if (sectionName == "Reading" || sectionName == "Science") {
        testTime = 35 * 60;
      } else {
        testTime = 60 * 60;
      }
      // testTime = 10;
      return Math.round(testTime);
    },
    getLetter(questionOrder, index) {
      // console.log(questionOrder);
      // console.log(index);
      const oddLetters = ["A", "B", "C", "D", "E"];
      const evenLetters = ["F", "G", "H", "J", "K"];
      if (questionOrder % 2 === 1) {
        return index;
      } else {
        let i = oddLetters.indexOf(index);
        return i > -1 ? evenLetters[i] : "-";
      }
    },
    showAnswers(answers) {
      let answer = "";
      if (answers && answers.length > 0) {
        answers.forEach((item, index) => {
          if (index === answers.length - 1) {
            answer += item.answers[0];
          } else {
            answer += item.answers[0] + " | ";
          }
        });
      }
      return answer;
    },
    getResolve(question_id) {
      return 0;
    },
    showTotalTime(activities) {
      let totalTime = 0;
      activities.forEach(active => {
        if (
          active.length > 0 &&
          active[0]["testTime"] &&
          active[active.length - 1]["testTime"]
        ) {
          totalTime +=
            active[0]["testTime"] - active[active.length - 1]["testTime"];
        }
      });
      if (totalTime < 0) totalTime = 0;
      if (totalTime < 0) totalTime = 0;
      return this.instant.formatSecondToMMSS(totalTime);
    },
    totalTime(activities) {
      let totalTime = 0;
      activities.forEach(active => {
        if (
          active.length > 0 &&
          active[0]["testTime"] &&
          active[active.length - 1]["testTime"]
        ) {
          totalTime +=
            active[0]["testTime"] - active[active.length - 1]["testTime"];
        }
      });
      if (totalTime < 0) totalTime = 1;
      return totalTime;
    },
    showTime(seconds) {
      let minute;
      let second;
      if (seconds && seconds > 0) {
        minute = Math.floor(seconds / 60);
        second = Math.floor(seconds) - minute * 60;
      } else {
        minute = 0;
        second = 0;
      }
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return `Time spent: ${minute}:${second}`;
    }
  }
};
</script>

<style scoped>
.answer-table {
  display: flex;
  justify-content: center;
}
.answer-table .table {
  width: auto;
  margin: 1rem 0;
}

table.table > tbody > tr > td.your_performance,
table.table > tbody > tr > td.score_level {
  text-align: left;
  padding: 10px;
}
table.table > tbody > tr > td.your_performance ul {
  list-style: disc;
  margin-bottom: 0;
}

.table-bordered {
  border: none;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
  text-align: center;
}
.table .green-td {
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: var(--themeColor);
}
td a {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}
td a:hover {
  background-color: #eee;
}
table.table td,
table.table th {
  padding: 0 !important;
  height: 45px;
  line-height: 45px;
}
@media screen and (max-width: 768px) {
  .answer-table {
    width: 100%;
  }
  .answer-table table.table {
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .table {
    position: relative;
    width: 100%;
    display: block;
  }
  thead {
    float: left;
  }
  tbody tr {
    display: inline-block;
  }
  th,
  td {
    height: 45px;
    padding: 0;
    line-height: 45px;
    display: block;
  }
  th {
    width: 150px;
  }
  .table td {
    padding: 0;
    min-width: 75px;
    max-width: 85px;
  }
  td a {
    cursor: pointer;
  }
}
</style>
