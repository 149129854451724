var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('el-alert',{staticClass:"mb-2",attrs:{"type":"info","closable":false,"show-icon":""}},[_c('div',[_vm._v(" Press and hold the "),_c('kbd',[_vm._v(" Windows: "),_c('b',[_vm._v("Alt")])]),_vm._v(" / "),_c('kbd',[_vm._v(" Mac: "),_c('b',[_vm._v("Option")])]),_vm._v(" key to zoom in or out. ")])]),_c('div',{staticClass:"table-box-cover-border"},[_c('div',{staticClass:"move-line"}),_c('div',{staticClass:"move-line-l"}),_c('div',{staticClass:"table-box-cover-box"},[_c('div',{staticClass:"table-box-cover"},[(_vm.questions)?_c('div',{staticClass:"table-box",class:{ 'height-table': _vm.questions.length > 30 }},[_c('div',{staticClass:"order-box mr-1"},_vm._l((_vm.questions),function(question,index){return _c('div',{key:question.id,class:{
                'order-item': true,
                'is-correct':
                  question.userAnswer && question.userAnswer.is_correct === 1,
                'is-error':
                  question.userAnswer && question.userAnswer.is_correct !== 1
              }},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.questions.length < 31),expression:"questions.length < 31"}]},[_vm._v(" "+_vm._s(index + 1)+" ")])])}),0),_c('div',{staticClass:"time-box"},[_c('div',{staticClass:"time-line-top"},_vm._l((Math.ceil(this.sectionTime / 60)),function(second){return _c('div',{key:second,staticClass:"time-line-second"},[_c('div',{staticClass:"time-second-box"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(second === 1),expression:"second === 1"}],staticClass:"time-box-start"},[_vm._v(" "+_vm._s(_vm.instant.formatSecondToMMSS((second - 1) * 60))+" ")]),_c('span',{staticClass:"time-box-end 1-M",class:{
                      '5-M': second % 5 === 0,
                      '2-M': second % 2 === 0
                    }},[_vm._v(" "+_vm._s(_vm.instant.formatSecondToMMSS(second * 60))+" ")]),_vm._v("   ")])])}),0),_vm._l((_vm.questions),function(question,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(question.userAnswer),expression:"question.userAnswer"}],key:question.id,staticClass:"time-children",style:(_vm.getQuestionWidth(question.userAnswer))},[_c('div',{staticClass:"time-line",class:{
                  'is-correct':
                    question.userAnswer &&
                    question.userAnswer.is_correct === 1,
                  'is-error':
                    question.userAnswer &&
                    question.userAnswer.is_correct !== 1
                },style:(`top: ${index * (_vm.questions.length > 30 ? 0.5 : 1.5)}rem;`)},[(question.userAnswer)?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","visible-arrow":false,"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('b',[_vm._v("#"+_vm._s(index + 1))]),_c('div',[_vm._v(" "+_vm._s(question.userAnswer.metadata ? ` ` + _vm.showTotalTime( question.userAnswer.metadata.activities ) : "")+" ")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.questions.length < 31),expression:"questions.length < 31"}],staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(question.userAnswer.metadata ? _vm.showTotalTime( question.userAnswer.metadata.activities ) : " ")+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.questions.length > 30),expression:"questions.length > 30"}],staticStyle:{"display":"block"}},[_vm._v("   ")])]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","visible-arrow":false,"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" You left the screen for 10 seconds ")]),(_vm.getLeaveTime(question.userAnswer))?_c('span',{staticClass:"text-warning leave-Icon"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()])]:[_vm._v("   ")]],2)])})],2),_c('div',{staticClass:"order-box ml-1"},_vm._l((_vm.questions),function(question,index){return _c('div',{key:question.id,class:{
                'order-item': true,
                'is-correct':
                  question.userAnswer && question.userAnswer.is_correct === 1,
                'is-error':
                  question.userAnswer && question.userAnswer.is_correct !== 1
              }},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.questions.length < 31),expression:"questions.length < 31"}]},[_vm._v(" "+_vm._s(index + 1)+" ")])])}),0)]):_vm._e()])])]),_c('div',{staticClass:"resize-button"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","visible-arrow":false,"placement":"top","content":"Zoom Out"}},[_c('el-button',{staticClass:"zoom-out",attrs:{"size":"mini","type":"primary","plain":""}},[_c('i',{staticClass:"fas fa-minus"})])],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","visible-arrow":false,"placement":"top","content":"Reset"}},[_c('el-button',{staticClass:"reset",attrs:{"size":"mini","type":"primary","plain":""}},[_c('i',{staticClass:"fas fa-redo"})])],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","visible-arrow":false,"placement":"top","content":"Zoom In"}},[_c('el-button',{staticClass:"zoom-in",attrs:{"size":"mini","type":"primary","plain":""}},[_c('i',{staticClass:"fas fa-plus"})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }